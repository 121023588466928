import UserProfile from "views/admin/UserProfile";
import Kits from "views/admin/Kits/index.jsx";

import SingleKit from "views/admin/Kits/SingleKit/index.jsx"
import Settings from "views/admin/Settings/index.jsx";

import Support from "views/admin/Support/index.jsx"
import NotFound from "views/admin/NotFound";

var routes = [
  {
    path: "/user-profile",
    name: "Perfil do usuário",
    invisible: true,
    component: UserProfile,
    layout: "/admin",
  },
  {
    path: "/index",
    name: "Kits de produtos",
    icon: "fas fa-layer-group",
    component: Kits,
    layout: "/admin",
  },
  
  {
    path: "/kit",
    name: "Gerenciamento de kit",
    invisible: true,
    component: SingleKit,
    layout: "/admin",
  },
  {
    path: "/configuracoes",
    name: "Configurações",
    icon: "fas fa-wrench",
    component: Settings,
    layout: "/admin",
  },

   // Manter o support e notfound sempre nas últimas posições respectivamente
  {
    path: "/suporte",
    name: "Suporte", //Nome dentro do componente
    icon: "far fa-question-circle", //Ícone da sidebar
    component: Support,
    layout: "/admin",
  },
  {
    path: "*",
    invisible: true,
    component: NotFound,
    layout: "/admin",
  },
];
export default routes;
