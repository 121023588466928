import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";

import Swal from "sweetalert2";
import "./styles.scss";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
} from "reactstrap";

import Page from "components/Page";
import api from "config/api";
import errorRequest from "common/errorRequest";

export default function Settings() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [idUser, setIdUser] = useState("");
  const [elementHtmlToRender, setElementHtmlToRender] = useState(
    ".section-single-product"
  );
  const [installments, setInstallments] = useState(6);
  const [notSellKitWithoutStock, setNotSellKitWithoutStock] = useState(false);
  const [callOnWhatsApp, setCallOnWhatsApp] = useState(false);

  useEffect(() => {
    fetchUser();
  }, []);

  // useEffect(()=> {
  //   if(notSellKitWithoutStock)
  //     document.getElementById("not-Sell-Kit-Without-Stock").checked = true;
  //   else
  //     document.getElementById("Sell-Kit-Without-Stock").checked = true;
  // }, [notSellKitWithoutStock]);

  // useEffect(()=> {
  //   if(callOnWhatsApp)
  //     document.getElementById("call-on-whats-app").checked = true;
  //   else
  //     document.getElementById("not-call-on-whats-app").checked = true;
  // }, [callOnWhatsApp]);

  async function fetchUser() {
    setLoading(true);
    try {
      const { data } = await api.get(`/user`);
      setIdUser(data.data.idUser);
      setElementHtmlToRender(data.data.elementHtmlToRender);
      setInstallments(data.data.installments);
      // setNotSellKitWithoutStock(data.data.notSellKitWithoutStock);
      // setCallOnWhatsApp(data.data.callOnWhatsApp);
    } catch (error) {
      errorRequest(history, error);
    }
    setLoading(false);
  }

  async function saveSettings() {
    if (!elementHtmlToRender || !installments) {
      Swal.fire(
        "Atenção",
        "Preencha todos os campos e tente novamente!",
        "warning"
      );
      return;
    }

    Swal.fire({
      title: "Atenção",
      text: "Deseja Realmente salvar?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim!",
    }).then(async (result) => {
      if (result.value) {
        setLoading(true);

        try {
          await api.put(`/user/${idUser}`, {
            elementHtmlToRender,
            installments,
            notSellKitWithoutStock,
            callOnWhatsApp,
          });

          Swal.fire(
            "Salvo!",
            "As configurações foram salvas com sucesso",
            "success"
          );
        } catch (error) {
          errorRequest(history, error);
        }
        setLoading(false);
      }
    });
  }

  return (
    <Page back loading={loading}>
      <Form>
        <Row>
          <Col className="col-5">
            {/* <FormGroup className="mb-1">
              <Label className="form-label" for="soldOutKit">
                Esgotar kit se houver produto esgotado *
              </Label>
              <FormGroup className="d-flex kit-radioform " tag="fieldset">
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="notSellKitWithoutStock"
                      id="not-Sell-Kit-Without-Stock"
                      onClick={() => setNotSellKitWithoutStock(true)}
                    />
                    Esgotar kit
                  </Label>
                </FormGroup>
                <FormGroup className="ml-3" check>
                  <Label check>
                    <Input
                      type="radio"
                      name="notSellKitWithoutStock"
                      id="Sell-Kit-Without-Stock"
                      onClick={() => setNotSellKitWithoutStock(false)}
                    />
                    Não esgotar kit
                  </Label>
                </FormGroup>
              </FormGroup>
            </FormGroup>
            <FormGroup className="mb-1">
              <Label className="form-label" for="whatsappCall">
                Chamada no whatsapp se houver produto esgotado *
              </Label>
              <FormGroup className="d-flex kit-radioform " tag="fieldset">
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="callOnWhatsApp"
                      id="call-on-whats-app"
                      onClick={() => setCallOnWhatsApp(true)}
                    />
                    Chamar no whatsapp
                  </Label>
                </FormGroup>
                <FormGroup className="ml-3" check>
                  <Label check>
                    <Input
                      type="radio"
                      name="callOnWhatsApp"
                      id="not-call-on-whats-app"
                      onClick={() => setCallOnWhatsApp(false)}
                    />
                    Não chamar
                  </Label>
                </FormGroup>
              </FormGroup>
            </FormGroup> */}
            <FormGroup>
              <Label className="form-label" for="inputPayment">
                Máximo de parcelas sem juros
              </Label>
              <InputGroup className="mb-1">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-credit-card" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="select"
                  value={installments}
                  onChange={(e) => setInstallments(e.target.value)}
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                </Input>
              </InputGroup>
              <Label className="form-label" for="inputDOM">
                Elemento DOM para montar o kit (avançado)
              </Label>
              <InputGroup className="mb-1">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="far fa-file-code" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  className="form-input"
                  type="text"
                  id="inputDOM"
                  placeholder="Elemento DOM"
                  value={elementHtmlToRender}
                  onChange={(e) => setElementHtmlToRender(e.target.value)}
                />
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
        <Button
          className="float-right mt-3"
          color="primary"
          onClick={(e) => saveSettings(e)}
        >
          Salvar
        </Button>
      </Form>
    </Page>
  );
}
