import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";

import { Modal, Label, Collapse, Card, Input, Button } from "reactstrap";

import api from "config/api";
import "./styles.scss";
import errorRequest from "common/errorRequest";

export default function ModalCategoriesNuvemShop({
  isOpen,
  toggle,
  categories,
  setCategories,
  setLoading,
}) {
  const [newCategory, setNewCategory] = useState(false);
  const [nuvemShopCategories, setNuvemShopCategories] = useState([]);
  const [seoOption, setSeoOption] = useState(false);
  const [name, setName] = useState("");
  const [parent, setParent] = useState(0);
  const [seoTitle, setSeoTitle] = useState("");
  const [seoDescription, setSeoDescription] = useState("");
  const history = useHistory();

  useEffect(() => {
    fetchNuvemShopCategories();
  }, []);

  async function fetchNuvemShopCategories() {
    setLoading(true);
    try {
      const { data } = await api.get(`/nuvemShopCategory`);

      var listCategories = [];
      if (data.data) {
        listCategories = data.data.map((category) => {
          return {
            label: category.name.pt,
            value: category.id,
          };
        });
      }

      setNuvemShopCategories([
        { value: 0, label: "Nenhum" },
        ...listCategories,
      ]);
    } catch (error) {
      errorRequest(history, error);
    }
    setLoading(false);
  }

  async function createCategory(event) {
    event.preventDefault();
    setLoading(true);

    try {
      const { data } = await api.post(`/nuvemShopCategory`, {
        name,
        parent,
        seoTitle,
        seoDescription,
      });
      Swal.fire("Pronto", "A categoria foi cadastrada com sucesso", "success");
      const category = { label: data.data.name.pt, value: data.data.id };
      setNuvemShopCategories([...nuvemShopCategories, category]);
      setCategories([...categories, category]);

      setName("");
      setParent(0);
      setSeoTitle("");
      setSeoDescription("");
      setNewCategory(!newCategory);
    } catch (error) {
      errorRequest(history, error);
    }
    setLoading(false);
  }

  return (
    <Modal
      size="md"
      className="category-modal"
      toggle={() => toggle()}
      isOpen={isOpen}
    >
      <div className="modal-header">
        <h3 className="modal-title">Adicionar categorias</h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => toggle()}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <Label>
          <small>Selecione uma categoria já criada para seu produto:</small>
        </Label>
        <Select
          className="categories-select"
          onChange={(e) => {
            if (e === null) {
              setCategories([]);
            } else {
              setCategories(e);
            }
          }}
          value={categories}
          name="categories"
          isMulti
          options={nuvemShopCategories}
          placeholder="Selecione uma categoria..."
        />
        <button
          onClick={() => setNewCategory(!newCategory)}
          className="link mt-2"
        >
          <small>
            <i className="fas fa-plus" /> Crie uma nova categoria
          </small>
        </button>
        <Collapse isOpen={newCategory}>
          <Card className="p-3">
            <Label for="category-name">Nome da nova categoria</Label>
            <Input
              id="category-name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <Label>Esta categoria será uma subcategoria de:</Label>
            <Select
              className="categories-select"
              value={nuvemShopCategories.find((x) => x.value === parent)}
              onChange={(e) => setParent(e.value)}
              options={nuvemShopCategories}
            />
            <button
              className="link mt-2"
              onClick={() => setSeoOption(!seoOption)}
            >
              <small>Opções de SEO</small>
              {seoOption ? (
                <i className="fas fa-chevron-up ml-1" />
              ) : (
                <i className="fas fa-chevron-down ml-1" />
              )}
            </button>
            <Collapse isOpen={seoOption}>
              <Label for="seo-title">Título para SEO</Label>
              <Input
                id="seo-title"
                value={seoTitle}
                onChange={(e) => setSeoTitle(e.target.value)}
              />
              <Label for="seo-description">Descrição para SEO</Label>
              <Input
                id="seo-description"
                value={seoDescription}
                onChange={(e) => setSeoDescription(e.target.value)}
              />
            </Collapse>
            <div className="d-flex justify-content-end">
              <Button
                className="mt-2"
                color="primary"
                onClick={(e) => createCategory(e)}
              >
                Adicionar nova categoria
              </Button>
            </div>
          </Card>
        </Collapse>
        <Button
          className="float-right mt-4"
          color="success"
          onClick={() => toggle()}
        >
          Pronto
        </Button>
      </div>
    </Modal>
  );
}
