import React from "react";

import formatCurrency from "common/formatCurrency";

export default function TableLineProductKit({ product, setProducts }) {
  function deleteProduct(event) {
    event.preventDefault();
    setProducts((products) =>
      products.filter(
        (x) => x.nuvemShopIdProduct !== product.nuvemShopIdProduct
      )
    );
  }

  return (
    <tr>
      <td className="TableTd">
        <div className="singleorder-table-imgwrapper">
          <img
            className="singleorder-table-img"
            src={product.urlProduct}
            alt="Imagem do produto"
          />
        </div>
      </td>
      <td className="TableTd text-left">{product.name}</td>
      <td className="TableTd">{formatCurrency(product.price)}</td>
      <td className="TableTd">
        <div className="table-action">
          <div
            className="table-action-button-delete"
            onClick={(e) => deleteProduct(e)}
          >
            <i className="fas fa-trash-alt" />
          </div>
        </div>
      </td>
    </tr>
  );
}
