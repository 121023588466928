import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { uniqueId } from "lodash";
import Swal from "sweetalert2";
import { Editor } from "@tinymce/tinymce-react";

import PicturesModal from "./PicturesModal";

import "./styles.scss";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Table,
  InputGroup,
  Button,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";

import ModalProductNuvemShop from "./ModalProductNuvemShop";
import ModalCategoriesNuvemShop from "./ModalCategoriesNuvemShop";
import TableLineProductKit from "./TableLineProductKit";

import formatCurrency from "common/formatCurrency";
import errorRequest from "common/errorRequest";
import Page from "components/Page";
import api from "config/api";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function SingleKit() {
  const history = useHistory();
  const query = useQuery();
  const idKit = query.get("idKit");
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [published, setPublished] = useState(true);
  const [images, setImages] = useState([]);
  const [modalImageOrder, setModalImageOrder] = useState(false);
  const [products, setProducts] = useState([]);
  const [modalProductNuvemShop, setModalProductNuvemShop] = useState(false);
  const [categories, setCategories] = useState([]);
  const [deleteImages, setDeleteImages] = useState([]);
  const [total, setTotal] = useState(0);
  const [modalCategoriesNuvemShop, setModalCategoriesNuvemShop] =
    useState(false);

  useEffect(() => {
    if (idKit) fetchKit();
  }, []);

  useEffect(() => {
    if (published) document.getElementById("public-show").checked = true;
    else document.getElementById("public-hide").checked = true;
  }, [published]);

  useEffect(() => {
    products && products.length
      ? setTotal(
          products
            .map((x) => parseFloat(x.price))
            .reduce((soma, num) => soma + num)
        )
      : setTotal(0);
  }, [products]);

  async function fetchKit() {
    setLoading(true);
    try {
      const { data } = await api.get(`/kit/${idKit}`);
      setName(data.data.name);
      setDescription(data.data.description);
      setPublished(data.data.published);
      setProducts(
        data.data.Products.map(
          ({ nuvemShopIdProduct, name, urlProduct, price }) => {
            return { nuvemShopIdProduct, name, urlProduct, price };
          }
        )
      );
      setCategories(
        data.data.Categories.map(({ name, nuvemShopIdCategory }) => {
          return { label: name, value: nuvemShopIdCategory };
        })
      );
      setImages(
        data.data.Images.map(({ idImage, urlImage, nameFile }) => {
          return {
            id: parseInt(uniqueId()),
            idImage,
            name: nameFile,
            preview: urlImage,
          };
        })
      );
    } catch (error) {
      errorRequest(history, error);
    }
    setLoading(false);
  }

  async function saveKit() {
    if (!name || !description) {
      Swal.fire(
        "Atenção",
        "Preencha todos os campos e tente novamente!",
        "warning"
      );
      return;
    }

    Swal.fire({
      title: "Atenção",
      text: "Deseja Realmente salvar?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim!",
    }).then(async (result) => {
      if (result.value) {
        setLoading(true);

        try {
          const formData = new FormData();

          for (let i = 0; i < images.length; i++) {
            const image = images[i];
            if (!image.idImage) formData.append("files", image.file);
          }

          formData.append("name", name);
          formData.append("description", description);
          formData.append("published", published);
          for (let i = 0; i < products.length; i++) {
            const product = products[i];
            formData.append("products[]", JSON.stringify(product));
          }

          for (let i = 0; i < categories.length; i++) {
            const { label, value } = categories[i];
            formData.append(
              "categories[]",
              JSON.stringify({ nuvemShopIdCategory: value, name: label })
            );
          }
          if (idKit)
            for (let i = 0; i < deleteImages.length; i++) {
              const idImage = deleteImages[i];
              formData.append("deleteImages[]", idImage);
            }

          if (idKit) await api.put(`/kit/${idKit}`, formData);
          else await api.post(`/kit`, formData);

          Swal.fire(
            "Salvo!",
            "As configurações foram salvas com sucesso",
            "success"
          );
          history.push("/admin/index");
        } catch (error) {
          errorRequest(history, error);
        }
        setLoading(false);
      }
    });
  }

  async function deleteImage(event, id) {
    event.preventDefault();

    Swal.fire({
      icon: "info",
      title: "Atenção",
      text: "Essa imagem será apagada, deseja continuar?",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Sim",
    }).then(async (res) => {
      if (res.value) {
        const image = images.find((image) => image.id === id);
        if (image && image.idImage)
          setDeleteImages((deleteImages) => [...deleteImages, image.idImage]);
        setImages((images) => images.filter((image) => image.id !== id));
      }
    });
  }

  async function deleteCategory(event, value) {
    event.preventDefault();

    Swal.fire({
      icon: "info",
      title: "Atenção",
      text: "Essa categoria será apagada, deseja continuar?",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Sim",
    }).then(async (res) => {
      if (res.value) {
        setCategories((categories) =>
          categories.filter((category) => category.value !== value)
        );
      }
    });
  }

  return (
    <Page loading={loading} back>
      <>
        <PicturesModal
          toggleModal={() => setModalImageOrder(!modalImageOrder)}
          isOpen={modalImageOrder}
          setImages={setImages}
          images={images}
          setDeleteImages={setDeleteImages}
        />
        <ModalProductNuvemShop
          isOpen={modalProductNuvemShop}
          toggle={() => setModalProductNuvemShop(!modalProductNuvemShop)}
          setLoading={setLoading}
          products={products}
          setProducts={setProducts}
        />
        <ModalCategoriesNuvemShop
          isOpen={modalCategoriesNuvemShop}
          toggle={() => setModalCategoriesNuvemShop(!modalCategoriesNuvemShop)}
          categories={categories}
          setCategories={setCategories}
          setLoading={setLoading}
        />
      </>
      <Form>
        <Row>
          <Col className="col-6">
            <FormGroup className="mb-1">
              <Label className="form-label" for="inputName">
                Nome do kit *
              </Label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-layer-group" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  className="form-input"
                  type="text"
                  placeholder="Nome"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </InputGroup>
            </FormGroup>
          </Col>
          <Col className="col-12">
            <FormGroup className="mb-1">
              <Label className="form-label" for="publicShow">
                Publicação do kit *
              </Label>
              <FormGroup className="d-flex kit-radioform " tag="fieldset">
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="publicShow"
                      id="public-show"
                      onClick={() => setPublished(true)}
                    />
                    Publicado
                  </Label>
                </FormGroup>
                <FormGroup className="ml-3" check>
                  <Label check>
                    <Input
                      type="radio"
                      name="publicShow"
                      id="public-hide"
                      onClick={() => setPublished(false)}
                    />
                    Oculto
                  </Label>
                </FormGroup>
              </FormGroup>
            </FormGroup>
          </Col>
          <Col className="col-9">
            <Label className="form-label pl-0 col-12" for="inputDesconto">
              Categorias do kit *
            </Label>
            <Button
              className="pl-0 text-primary"
              color="link"
              onClick={() => setModalCategoriesNuvemShop(true)}
            >
              {categories.length > 0
                ? "Gerenciar categorias do kit"
                : "Adicionar categorias no kit"}
            </Button>
          </Col>
          <Col className="col-9 mb-2">
            <div className="kit-categories">
              {categories &&
                categories.length > 0 &&
                categories.map((category) => (
                  <span
                    key={category.value + "-category"}
                    className="kit-categories-preview"
                  >
                    {category.label}
                    <i
                      className="fas fa-times"
                      onClick={(e) => deleteCategory(e, category.value)}
                    />
                  </span>
                ))}
            </div>
          </Col>
          {((idKit && description) || !idKit) && (
            <Col className="col-9">
              <Label className="form-label">Descrição do kit *</Label>
              <Editor
                value={description}
                apiKey="l8ea9dxbiic0hs29hckgmf03e53fqj84nnotzhnxqjicdk9t"
                menubar="false"
                init={{
                  height: 350,
                  selector: "textarea",
                  placeholder: "Descrição...",
                  language: "pt_BR",
                  resize: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | " +
                    " bold italic backcolor | alignleft aligncenter " +
                    " alignright alignjustify | bullist numlist outdent indent |" +
                    " removeformat | help | code",
                  content_css: [
                    "//fonts.googleapis.com/css?family=Open+Sans:100,200,300,400,600,700",
                  ],
                }}
                onEditorChange={(e) => setDescription(e)}
                // onChange={(e) => setDescription(e.target.getContent())}
              />
            </Col>
          )}
          <Col className="col-12 mt-3 mb-2">
            <div className="kit-preview">
              {images &&
                images.length > 0 &&
                images.map((image) => (
                  <div key={image.id + "-image"} className="kit-preview-img">
                    <span
                      className="kit-img-delete"
                      onClick={(e) => deleteImage(e, image.id)}
                    >
                      <i className="fas fa-times" />
                      <img
                        src={image.preview}
                        alt="Preview de imagem de referencia para personalização"
                      />
                    </span>
                  </div>
                ))}
            </div>
          </Col>
          <Col className="col-9 d-flex justify-content-end">
            <Button
              className="mb-3 text-primary"
              color="link"
              onClick={() => setModalImageOrder(true)}
            >
              {images.length > 0
                ? "Gerenciar imagens do kit"
                : "Adicionar imagens no kit"}
            </Button>
            <Button
              className="mb-3"
              color="primary"
              onClick={() => setModalProductNuvemShop(!modalProductNuvemShop)}
            >
              Adicionar produtos
            </Button>
          </Col>
          <Col className="col-9">
            <Table className="d-none d-md-table text-center" responsive>
              <thead>
                <tr>
                  <th className="TableTh">Imagem</th>
                  <th className="TableTh text-left">Produto</th>
                  <th className="TableTh">Preço</th>
                  <th className="TableTh">Ações</th>
                </tr>
              </thead>
              <tbody>
                {products && products.length > 0 ? (
                  products.map((product) => (
                    <>
                      <TableLineProductKit
                        key={product.nuvemShopIdProduct + "-product"}
                        product={product}
                        setProducts={setProducts}
                      />
                    </>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6">Nenhum produto foi adicionando...</td>
                  </tr>
                )}
              </tbody>
            </Table>
            <Table>
              <thead>
                <tr>
                  <th className="TableTh d-flex justify-content-end">
                    Preço:{" "}
                    <span className="ml-1 mr-4 text-muted">
                      {formatCurrency(total)}
                    </span>
                  </th>
                </tr>
              </thead>
            </Table>
          </Col>
        </Row>
        <Button
          className="float-right mt-3"
          color="primary"
          onClick={(e) => saveKit(e)}
        >
          Salvar
        </Button>
      </Form>
    </Page>
  );
}
