import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";

import Swal from "sweetalert2";
import "./styles.scss";
import {
  Row,
  Col,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Button,
  Input,
  Table,
} from "reactstrap";

import Page from "components/Page";
import api from "config/api";
import errorRequest from "common/errorRequest";
import formatCurrency from "common/formatCurrency";
import CustomPagination from "components/CustomPagination";

const noImage = require("assets/img/brand/no-image.png");

export default function Kits() {
  const nuvemShopOriginalDomain = localStorage.getItem(
    "nuvemShopOriginalDomain"
  );
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [kits, setKits] = useState([]);
  const [published, setPublished] = useState("");
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState();
  const limit = 10;

  useEffect(() => {
    fetchKits();
  }, [page, published]);

  async function fetchKits() {
    setLoading(true);
    try {
      const { data } = await api.get(
        `/kit?limit=${limit}&offset=${
          page - 1
        }&search=${search}&published=${published}`
      );

      setKits(data.data);
      setTotal(data.pagination.total);
    } catch (error) {
      errorRequest(history, error);
    }
    setLoading(false);
  }

  async function deleteKit(event, idKit) {
    event.preventDefault();

    Swal.fire({
      title: "Atenção",
      text: "Deseja realmente excluir esse Kit de produtos?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim!",
    }).then(async (result) => {
      if (result.value) {
        setLoading(true);
        try {
          await api.delete(`/kit/${idKit}`);
          Swal.fire(
            "Pronto",
            "O kit de produtos foi excluido com sucesso",
            "success"
          );
          setKits((kits) => kits.filter((kit) => kit.idKit !== idKit));
        } catch (error) {
          errorRequest(history, error);
        }
        setLoading(false);
      }
    });
  }

  return (
    <Page
      pageHeader
      loading={loading}
      title="Bem-vindo(a) ao painel do Kits de Produtos"
    >
      <>
        <Row className="mb-4">
          <Col md="4">
            <InputGroup>
              <Input
                className="table-search-input"
                placeholder="Buscar..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    fetchKits();
                  }
                }}
              />
              <InputGroupAddon color="primary" addonType="append">
                <Button
                  className="table-search-button"
                  onClick={() => fetchKits()}
                >
                  <i className="fas fa-search" />
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </Col>
          <Col md="4">
            <FormGroup className="mb-0">
              <Input
                type="select"
                onChange={(e) => setPublished(e.target.value)}
              >
                <option value="">Todos os resultados</option>
                <option value="true">Publicados</option>
                <option value="false">Não publicados</option>
              </Input>
            </FormGroup>
          </Col>
          <Col md="4" className="d-flex justify-content-end">
            <Button color="primary" onClick={() => history.push(`/admin/kit`)}>
              Criar novo kit
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table className="d-none d-md-table" responsive striped>
              <thead>
                <tr>
                  <th className="TableTh w-auto">Imagem</th>
                  <th className="TableTh w-100">Kit</th>
                  <th className="TableTh">Preço</th>
                  <th className="TableTh w-100">Publicado</th>
                  <th className="TableTh text-center w-25">Ações</th>
                </tr>
              </thead>
              <tbody>
                {kits && kits.length === 0 ? (
                  <tr>
                    <td className="text-center" colSpan="5">
                      Nenhum registro foi encontrado...
                    </td>
                  </tr>
                ) : (
                  kits.map((kit) => (
                    <tr key={kit.idKit}>
                      <td className="TableTd">
                        <div className="TableImgTd">
                          <img
                            className="TableImg"
                            src={
                              kit.Images && kit.Images.length > 0
                                ? kit.Images[0].urlImage
                                : noImage
                            }
                            alt="imagem do kit"
                          />
                        </div>
                      </td>
                      <td className="TableTd">
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href={`https://${nuvemShopOriginalDomain}/admin/products/${kit.nuvemShopIdProduct}`}
                        >
                          {kit.name} <i class="fas fa-external-link-alt" />
                        </a>
                      </td>
                      <td className="TableTd">{formatCurrency(kit.price)}</td>
                      <td className="TableTd">
                        {kit.published ? "Sim" : "Não"}
                      </td>
                      <td className="TableTd">
                        <div className="table-action">
                          <div
                            className="table-action-button-edit"
                            onClick={() =>
                              history.push(`/admin/kit?idKit=${kit.idKit}`)
                            }
                          >
                            <i className="fas fa-edit" />
                          </div>
                          <div
                            className="table-action-button-delete"
                            onClick={(e) => deleteKit(e, kit.idKit)}
                          >
                            <i className="fas fa-trash-alt" />
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
            {total > limit && (
              <div className="d-flex my-3 justify-content-center">
                <CustomPagination
                  total={total}
                  page={page}
                  length={kits.length}
                  limit={limit}
                  setPage={setPage}
                />
              </div>
            )}
          </Col>
        </Row>
      </>
    </Page>
  );
}
