import React from "react";

import "./styles.scss";

import formatCurrency from "common/formatCurrency";

const noImage = require("assets/img/brand/no-image.png");

export default function TableLineProduct({
  product,
  lastProductElementRef,
  setProducts,
  products,
}) {
  function addProduct() {
    // if (!products.find((x) => x.nuvemShopIdProduct === product.id)) {
    const newProduct = {
      name: product.name.pt,
      urlProduct:
        product.images && product.images.length
          ? product.images[0].src
          : noImage,
      nuvemShopIdProduct: product.id,
      price:
        product.variants && product.variants.length > 0
          ? product.variants[0].promotional_price
            ? product.variants[0].promotional_price
            : product.variants[0].price
          : 0,
    };
    setProducts((products) => [...products, newProduct]);
    // }
  }

  return (
    <tr ref={lastProductElementRef}>
      <td className="TableTd">
        <div className="singleorder-table-imgwrapper">
          <img
            className="singleorder-table-img"
            src={
              product.images && product.images.length
                ? product.images[0].src
                : noImage
            }
            alt="Imagem do produto"
          />
        </div>
      </td>
      <td className="TableTd text-left">{product.name.pt}</td>
      <td className="TableTd">
        {formatCurrency(
          product.variants[0].promotional_price
            ? product.variants[0].promotional_price
            : product.variants[0].price
        )}
      </td>
      <td className="TableTd">
        <div className="table-action">
          <div
            className="table-action-button-edit"
            onClick={() => window.open(product.canonical_url)}
          >
            <i className="fas fa-eye" />
          </div>
          <div
            className="table-action-button-sucess"
            onClick={() => addProduct()}
          >
            <i className="fas fa-arrow-right" />
          </div>
        </div>
      </td>
    </tr>
  );
}
